import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Header = ({ toggleMenu }) => (
  <header className="fixed top-0 left-0 right-0 z-50 bg-white bg-opacity-80 backdrop-blur-md">
    <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <motion.div
        className="text-2xl font-light"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        AQ
      </motion.div>
      <nav className="hidden md:flex space-x-6">
        {['About', 'Projects', 'Contact'].map((item) => (
          <motion.a
            key={item}
            href={`#${item.toLowerCase()}`}
            className="text-gray-600 hover:text-gray-900 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {item}
          </motion.a>
        ))}
      </nav>
      <motion.div
        className="md:hidden text-2xl cursor-pointer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={toggleMenu}
      >
        ☰
      </motion.div>
    </div>
  </header>
);

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        className="fixed inset-0 bg-white z-50"
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <div className="flex flex-col h-full justify-center items-center space-y-8">
          <motion.div
            className="absolute top-4 right-4 text-2xl cursor-pointer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleMenu}
          >
            ✕
          </motion.div>
          {['About', 'Projects', 'Contact', 'Models', 'Search', 'Discord', 'GitHub', 'Download', 'Sign in'].map((item) => (
            <motion.a
              key={item}
              href={`#${item.toLowerCase()}`}
              className="text-2xl text-gray-800 hover:text-gray-600"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toggleMenu}
            >
              {item}
            </motion.a>
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const IconCloud = () => {
  const icons = [
    { name: 'HTML5', class: 'fab fa-html5' },
    { name: 'CSS3', class: 'fab fa-css3-alt' },
    { name: 'JavaScript', class: 'fab fa-js' },
    { name: 'React', class: 'fab fa-react' },
    { name: 'Node.js', class: 'fab fa-node-js' },
    { name: 'Python', class: 'fab fa-python' },
    { name: 'Git', class: 'fab fa-git-alt' },
    { name: 'npm', class: 'fab fa-npm' },
    { name: 'Sass', class: 'fab fa-sass' },
    { name: 'Angular', class: 'fab fa-angular' },
    { name: 'Vue.js', class: 'fab fa-vuejs' },
    { name: 'Docker', class: 'fab fa-docker' },
  ];

  return (
    <motion.div
      className="flex flex-wrap justify-center items-center gap-8 my-12"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {icons.map((icon, index) => (
        <motion.div
          key={icon.name}
          className="text-4xl text-indigo-600 hover:text-indigo-800 transition-colors duration-300"
          whileHover={{ scale: 1.2, rotate: 360 }}
          whileTap={{ scale: 0.8 }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: index * 0.1 }}
        >
          <i className={icon.class} title={icon.name}></i>
        </motion.div>
      ))}
    </motion.div>
  );
};

const MainContent = () => (
  <main className="container mx-auto px-4 py-20 md:py-32 text-center">
    <IconCloud />
    <motion.h1
      className="text-5xl md:text-6xl font-extralight mb-6 text-gray-900"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
    >
      Aasim Qureshi
    </motion.h1>
    <motion.p
      className="text-xl md:text-2xl text-gray-600 mb-10 max-w-2xl mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.5 }}
    >
      Design. Build. Deploy.
    </motion.p>
    <motion.a
      href="https://cal.com/aasim.in/brain-storming"
      className="inline-block bg-indigo-600 text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-indigo-700 transition-colors"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.6, duration: 0.5 }}
    >
      Get in touch
    </motion.a>
  </main>
);

const DockIcon = ({ icon, url }) => (
  <motion.a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="bg-white p-3 rounded-full shadow-lg text-gray-600 hover:text-indigo-600 transition-colors duration-300"
    whileHover={{ y: -10, scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <i className={`fab fa-${icon} text-2xl`}></i>
  </motion.a>
);

const Footer = () => (
  <footer className="fixed bottom-0 left-0 right-0 pb-4">
    <motion.div
      className="flex justify-center space-x-4"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.5 }}
    >
      <DockIcon icon="github" url="https://github.com/itisaasim" />
      <DockIcon icon="linkedin" url="https://linkedin.com/in/itsaasim" />
      <DockIcon icon="twitter" url="https://twitter.com/itisaasim" />
      <DockIcon icon="instagram" url="https://instagram.com/itisaasim" />
      <DockIcon icon="medium" url="https://medium.com/@itisaasim" />
    </motion.div>
  </footer>
);

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-purple-50">
      <Header toggleMenu={toggleMenu} />
      <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <MainContent />
      <Footer />
    </div>
  );
};

export default App;